<template>
  <b-container fluid>
    <b-row v-if="user != null">
      <b-col>
        <b-card :header="'Gebruiker - ' + user.Username" class="mt-4">

          <table class="table table-bordered">
            <tr>
              <th>Username</th><td>{{ user.Username }}</td>
              <th>Type</th><td>{{ user.Type }}</td>
            </tr>
            <tr>
              <th>Naam</th><td>{{ user.ContactName }}</td>
              <th>Status</th><td>{{ user.Status }}</td>
            </tr>
            <tr>
              <th>E-mail</th><td>{{ user.Email }}</td>
              <th>Allow Analytics</th><td>{{ user.AllowAnalytics|yesno }}</td>
            </tr>
            <tr>
              <th>Contract</th>
              <td>
                <router-link :to="{ name: 'contract-detail', params: { id: user.ContractID } }">
                  {{ user.Contract.Name }} ({{ user.Contract.ContractID }})
                </router-link>
              </td>
              <th>Allow Royalties</th><td>{{ user.AllowRoyalty|yesno }}</td>
            </tr>
            <tr>
              <th>Contract naam</th><td>{{ user.Contract.Name }}</td>
              <th>Allow Trends</th><td>{{ user.AllowTrends|yesno }}</td>
            </tr>
            <tr>
              <th>Contract Bedrijf</th><td>{{ user.Contract.Company }}</td>
              <th>Allow Manage Release Concepts</th><td>{{ user.AllowManageReleaseConcepts|yesno }}</td>
            </tr>
            <tr>
              <th>Aangemaakt</th><td>{{ user.Created|datetimeFormat }}</td>
              <th>Aangemaakt door</th><td>{{ user.CreatedBy }}</td>
            </tr>
            <tr>
              <th>Laatst gewijzigd</th><td>{{ user.Modified|datetimeFormat }}</td>
              <th>Aangepast door</th><td>{{ user.ModifiedBy }}</td>
            </tr>
            <tr>
              <th>Laatste inlog</th><td>{{ user.LastLogin|datetimeFormat }}</td>
              <th></th><td></td>
            </tr>
          </table>
      
          <b-button class="mr-2" v-if="user.Status == 'new'" @click="sendWelcomeMail">Verstuur welkomstmail</b-button>
          <b-button class="mr-2" v-if="user.Status != 'new'" @click="sendPasswordResetMail">Verstuur wachtwoord reset mail</b-button>
          <b-button @click="getPasswordResetLink">Maak wachtwoord reset link</b-button>
          <p class="mt-3" v-if="passwordResetLink">Wachtwoord reset link: {{ passwordResetLink }}</p>
        </b-card>

      </b-col>
    </b-row>
  </b-container>
</template>

<script>

export default {
  name: "UserDetail",
  components: {
  },
  data() {
    return {
      passwordResetLink: null,
    };
  },
  props: {
    user: Object,
  },
  methods: {
    getPasswordResetLink () {
      this.$http.put('/users/'+ this.user.UserID +'/get-password-reset-link')
        .then(response => {
          this.passwordResetLink = response.data.link
          console.log(response.data)
        }).catch(() => {
          this.$notify({
            type: 'warn',
            text: 'Probleem bij het verkrijgen van de link.'
          });
        })
    },
    sendPasswordResetMail () {
      this.$http.put('/users/'+ this.user.UserID +'/send-password-reset-email')
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'E-mail verzonden'
          });
        }).catch(() => {
          this.$notify({
            type: 'warn',
            text: 'Probleem bij het verzenden van de e-mail.'
          });
        })
    },
    sendWelcomeMail () {
      this.$http.put('/users/'+ this.user.UserID +'/send-onboarding-email')
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'E-mail verzonden'
          });
        }).catch(() => {
          this.$notify({
            type: 'warn',
            text: 'Probleem bij het verzenden van de e-mail.'
          });
        })
    },
  },
  watch: {
  },
  mounted() {
    this.$root.$data.crumbs = [
      { to: { name: "user-search" }, text: "Gebruikers" },
      { text: this.user.Username },
    ];
  }
};
</script>

<style lang="scss" scoped>

</style>